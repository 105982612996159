import { template as template_b1f2d0a2826b402dbbb045d1f64ec399 } from "@ember/template-compiler";
const WelcomeHeader = template_b1f2d0a2826b402dbbb045d1f64ec399(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
