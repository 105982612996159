import { template as template_ac143d2121de4fa4aed30c3dc74ad23f } from "@ember/template-compiler";
const FKControlMenuContainer = template_ac143d2121de4fa4aed30c3dc74ad23f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
