import { template as template_d0972f4c5a4b4252ac2dba99b2c2b397 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_d0972f4c5a4b4252ac2dba99b2c2b397(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
